import { Button, Container, Grid, Link } from "@mui/material";
import { Box } from "@mui/system";
import ScrollAnimation from "react-animate-on-scroll";

const size = 300;

const text = `Екипът на Enter21 притежава богат опит в разработката на уеб сайтове - както онлайн магазини, така и такива с маркетингова цел. Разбиранията ни за модерен дизайн, отлични програмистски практики и маркетингово пласиране ще гарантират възвръщаемостта на вашата уеб инвестиция в света на мобилните устройства.`;
const textNew = `През 2017, екипът ни беше вдъхновен да започне Enter21 след сблъсък със застоялата действителност на българската уеб индустрия. Нашите специалисти не работят с шаблони, а изграждат сайтове специално пригодени за нуждите и изискванията на вашия бизнес. Впечатлете клиентите си с модерен индивидуален дизайн и лесно потребителско преживяване.`;

const LHS = () => (
  <img
    height={size}
    width={size}
    style={{ zIndex: 10, position: "absolute" }}
    src="circle.svg"
    alt="About us"
  />
);

const RHS = () => (
  <Grid container justifyContent="center" spacing={4} flexDirection="column">
    <Grid item>{textNew}</Grid>
    <Grid item>
      <Link underline="none" href="mailto:sales@enter21.io">
        <Button color="secondary" variant="contained">
          Направи запитване
        </Button>
      </Link>
    </Grid>
  </Grid>
);

const AboutUs = () => {
  const bgSize = (size * 92) / 100;
  return (
    <Container>
      <Grid container spacing={6} justifyContent="center" alignItems="center">
        <Grid item>
          <Box width={size} height={size}>
            <ScrollAnimation animateIn="animate__fadeIn" animateOnce>
              <Box
                borderRadius="50%"
                sx={{
                  backgroundColor: "#fff",
                  opacity: 0.5,
                }}
                ml={`${(size - bgSize) / 2 + 6}px`}
                mt={`${(size - bgSize) / 2}px`}
                width={bgSize}
                height={bgSize}
                position="absolute"
              />
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeIn" animateOnce>
              <LHS />
            </ScrollAnimation>
          </Box>
        </Grid>
        <Grid item md={6}>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce>
            <RHS />
          </ScrollAnimation>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutUs;
