import { AppBar, Button, Grid, Link } from "@mui/material";
import sectionIdentifiers from "../../constants/sectionIdentifiers";

const Header = () => {
  return (
    <AppBar position="static" elevation={0} color="transparent">
      <Grid container alignItems="center" justifyContent={"space-between"}>
        <Grid item>
          <Link href="/">
            <Button>
              <img
                src="/logo-white.png"
                alt="Enter 21"
                style={{
                  marginTop: 4,
                  color: "#ffffff",
                  padding: 4,
                  height: 40,
                }}
              />
            </Button>
          </Link>
        </Grid>
        <Grid item>
          <Button href={`#${sectionIdentifiers.weOffer}`} color="inherit">
            Услуги
          </Button>
          <Button href={`#${sectionIdentifiers.projects}`} color="inherit">
            Проекти
          </Button>
          <Button href={`#${sectionIdentifiers.careers}`} color="inherit">
            Кариери
          </Button>
        </Grid>
      </Grid>
    </AppBar>
  );
};
export default Header;
