import {
  Container,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import sectionIdentifiers from "../constants/sectionIdentifiers";
import OurWork from "../OurWork/OurWork";
import AboutUs from "./AboutUs/AboutUs";
import BannerHeader from "./BannerHeader/BannerHeader";
import CardsGrid from "./CardsGrid/CardsGrid";
import Header from "./Header/Header";
import PageContent from "./PageContent/PageContent";
import Pricing from "./Pricing/Pricing";
import "animate.css/animate.min.css";
import Footer from "./Footer/Footer";

const MainPage = () => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("xl"));
  return (
    <Box
      sx={{
        background:
          "linear-gradient(132deg, #ffffff 15%, rgba(94, 48, 240,0.17) 30%,transparent 50%,rgba(112,253,211,0.18) 60%, transparent)",
        backgroundSize: "400% 400%",
        animation: "gradbg 15s ease infinite",
        "@keyframes gradbg": {
          "0%": {
            backgroundPosition: "0% 50%",
          },
          "50%": {
            backgroundPosition: "50% 100%",
          },
          "100%": {
            backgroundPosition: "0% 50%",
          },
        },
      }}
    >
      <Box
        sx={{
          backgroundImage: "url(/banner-bg.svg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          maxWidth: "2500px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            height: match ? "740px" : "560px", // this is temp
          }}
        >
          <Box color="#fff" height="100%">
            <Header />
            <BannerHeader />
          </Box>
        </Container>
      </Box>
      <Box mt={match ? 40 : 30} pb={4} id={sectionIdentifiers.weOffer}>
        <PageContent />
        <Box mt={6} mb={20}>
          <CardsGrid />
        </Box>

        <Box mb={20} bgcolor="#ffffff87" pt={10} pb={10}>
          <AboutUs />
        </Box>

        <Box id={sectionIdentifiers.projects}>
          <OurWork />
        </Box>

        <Box
          pt={4}
          pb={12}
          bgcolor="#ffffff87"
          mt={20}
          mb={0}
          id={sectionIdentifiers.careers}
        >
          <Pricing />
        </Box>

        <Box m={6}>
          <Divider />
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default MainPage;
