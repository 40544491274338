import {
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import ScrollAnimation from "react-animate-on-scroll";

const lorem = `Design and simplicity are at the core of our
website builder. Easily create, customize, and
promote a stunning website that's search
engine ready all on your own with the power of
Mailchimp’s platform.`;

const hypeUp = `Търсим млад и ентусиасизиран full stack инженер да се присъедини към нашата мисия да доведем българските онлайн магазини в 21ви век`;

const pricingTopics = [
  "We meet to discuss the project requirements and scope of work",
  "We give you the pricing upfront",
  "You only start paying once the website is completed and running",
];

const hiringTopics = (small: boolean) => [
  "Гъвкава работна среда и дистанционна работа",
  "Обучение и възможности за развитие",
  <span>
    Работа с модерни технологии - AWS,
    {!small && <br />} React, Go
  </span>,
];

const Pricing = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container>
      <Box
        mt={6}
        sx={{
          maxWidth: theme.breakpoints.values.md,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Typography
          color="primary"
          gutterBottom
          variant="h4"
          align={isSmall ? undefined : "center"}
        >
          Присъединете се към нас
        </Typography>
        <Typography
          sx={{
            maxWidth: theme.breakpoints.values.md,
            marginLeft: "auto",
            marginRight: "auto",
          }}
          gutterBottom
          align={isSmall ? undefined : "center"}
        >
          {hypeUp}
        </Typography>
        <Box
          display="flex"
          justifyContent={isSmall ? undefined : "center"}
          mt={6}
        >
          <Button
            size="large"
            target="_blank"
            href="https://gigantic-shelf-305.notion.site/Full-Stack-Engineer-907987e2cf9f425c9429ee03d3020bf9"
            color="primary"
          >
            Прочети повече
          </Button>
        </Box>

        <Box mt={6}>
          <Grid container justifyContent={"space-between"} spacing={2}>
            {hiringTopics(isSmall).map((topic, index) => (
              <Grid item md={4}>
                <Box>
                  <ScrollAnimation
                    animateIn="animate__fadeIn"
                    delay={100 * index}
                    animateOnce
                  >
                    <Typography
                      align={isSmall ? undefined : "center"}
                      variant="h6"
                    >
                      {topic}
                    </Typography>
                  </ScrollAnimation>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Pricing;
