import { Container, Typography } from "@mui/material";

const copy = (
  <>
    Онлайн магазини или информационни страници, <br />
    ние подхождаме с индивидуалност и внимание към всеки детайл
  </>
);

const PageContent = () => {
  return (
    <Container>
      <Typography gutterBottom color="primary" align="center" variant="h4">
        Какво предлагаме
      </Typography>
      <Typography align="center">{copy}</Typography>
    </Container>
  );
};

export default PageContent;
