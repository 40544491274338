import { CssBaseline, ThemeProvider } from "@mui/material";
import LanguageWrapper from "./globalization/LanguageWrapper";
import MainPage from "./MainPage/MainPage";
import getTheme from "./theme";
import { useLocalStorage } from "react-use";
import TagManager from "react-gtm-module";

import "@fontsource/noto-sans";
import "@fontsource/noto-sans/300.css";
import "@fontsource/noto-sans/500.css";
import "@fontsource/noto-sans/700.css";

function App() {
  const [theme] = useLocalStorage("theme", "light");
  const tagManagerArgs = {
    gtmId: "GTM-WK3WS5B",
  };

  TagManager.initialize(tagManagerArgs);
  return (
    <ThemeProvider theme={getTheme(theme)}>
      <LanguageWrapper>
        <CssBaseline />
        <MainPage />
      </LanguageWrapper>
    </ThemeProvider>
  );
}

export default App;
