import {
  Box,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

const socialMedia = [
  {
    title: "linkedin",
    icon: LinkedInIcon,
    href: "https://www.linkedin.com/company/enter-21",
  },
  {
    title: "facebook",
    icon: FacebookIcon,
  },
  {
    title: "instagram",
    icon: InstagramIcon,
  },
];

const Footer = () => {
  return (
    <Box pb={4}>
      <Grid container spacing={3} justifyContent="center">
        {socialMedia.map((social) => {
          return (
            <Grid item>
              <Link
                target="_blank"
                href={social.href ? social.href : undefined}
              >
                <Tooltip placement="top" title={social.href ? "" : "Очаквайте скоро"}>
                  <IconButton>
                    <social.icon fontSize="large" />
                  </IconButton>
                </Tooltip>
              </Link>
            </Grid>
          );
        })}
      </Grid>

      <Grid
        container
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item>
          <Typography align="center">
            Copyright © {new Date().getFullYear()}
          </Typography>
        </Grid>
        <Grid item>
          <img
            src="/logo-colored.png"
            alt="Enter 21"
            style={{
              marginTop: 4,
              color: "#ffffff",
              marginRight: 2,
              padding: 4,
              height: 40,
              display: "inline",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default Footer;
