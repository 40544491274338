import { IntlProvider } from "react-intl";
import loadLocaleData from "./loadLocaleData";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const LanguageWrapper = ({ children }: Props) => {
  return (
    <IntlProvider
      messages={loadLocaleData("bg")}
      locale="bg"
      defaultLocale="bg"
    >
      {children}
    </IntlProvider>
  );
};

export default LanguageWrapper;
