import {
  Box,
  Card,
  CardMedia,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRef, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";

const cards = [
  {
    label: "Иновативен дизайн",
    icon: "card-icons/design.svg",
    gif: "card-icons/design.gif",
  },
  {
    label: "Безплатни консултации",
    icon: "card-icons/consultation.svg",
    gif: "card-icons/consultation.gif",
  },
  {
    label: "Изработка от А до Я",
    icon: "card-icons/layers.svg",
    gif: "card-icons/layers.gif",
  },
  {
    label: "Без шаблони",
    icon: "card-icons/pen.svg",
    gif: "card-icons/pen.gif",
  },
  {
    label: "Непрекъснати подобрения",
    icon: "card-icons/plus.svg",
    gif: "card-icons/plus.gif",
  },
  {
    label: "Хостинг и SEO",
    icon: "card-icons/seo.svg",
    gif: "card-icons/seo.gif",
  },
];

const AnimatedCard = ({
  card,
  index,
}: {
  card: typeof cards[0];
  index: number;
}) => {
  const ref = useRef(null);

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Grid
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      item
      xs={12}
      sm={6}
      md={4}
      lg={4}
      ref={ref}
    >
      <ScrollAnimation
        animateIn="animate__fadeIn"
        delay={100 * index}
        animateOnce
        style={{ height: "100%" }}
      >
        <Card
          elevation={0}
          sx={{ height: "100%", backgroundColor: "rgba(255,255,255,0.65)" }}
        >
          <CardMedia sx={{ textAlign: "center", marginTop: 3 }}>
            <img
              height={150}
              width={150}
              src={isHovered ? card.gif : card.icon}
              alt=""
            />
          </CardMedia>
          <Box sx={{ padding: "0 10px 10px 10px" }}>
            <Typography
              color="inherit"
              sx={{ color: "#545454", fontWeight: 300 }}
              variant="h5"
              align="center"
            >
              {card.label}
            </Typography>
          </Box>
        </Card>
      </ScrollAnimation>
    </Grid>
  );
};

const CardsGrid = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container>
      <Box maxWidth="780px" marginLeft="auto" marginRight="auto">
        <Grid container spacing={isSmall ? 2 : isMedium ? 4 : 8}>
          {cards.map((card, id) => (
            <AnimatedCard key={id} card={card} index={id} />
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default CardsGrid;
