import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app

const getTheme = (themeMode: string | undefined) =>
  responsiveFontSizes(
    createTheme({
      palette: {
        mode: themeMode && themeMode === "dark" ? "dark" : "light",
        primary: {
          main: "#0358ff",
        },
        secondary: {
          main: "#40fcc4",
        },
        error: {
          main: red.A400,
        },
        background: {
          default: themeMode === "dark" ? "#121212" : "#e9f0ff",
        },
        text: {
          primary: themeMode === "dark" ? "#fff" : "#464444",
        },
      },
      typography: {
        fontFamily: ["Noto Sans", "sans-serif"].join(","),
        h1: {
          fontSize: "4rem",
          fontWeight: 350,
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 480,
          md: 768,
          lg: 1024,
          xl: 1536,
        },
      },
    })
  );
export default getTheme;
