import {
  Button,
  Container,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { FormattedMessage } from "react-intl";
import ScrollAnimation from "react-animate-on-scroll";

const BannerHeader = () => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("xl"));
  const xtraSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container
      disableGutters
      sx={{
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingBottom: 20,
        alignItems: "center",
        marginTop: match ? 20 : 9,
      }}
    >
      <Grid
        item
        justifyContent="center"
        sx={{
          maxWidth: "600px",
        }}
      >
        <Typography
          variant="h1"
          align={xtraSmall ? undefined : "center"}
          gutterBottom
        >
          <FormattedMessage
            defaultMessage="Вашият модерен уеб сайт"
            id="mainPage.header.slogan"
          />
        </Typography>
        <ScrollAnimation animateIn="animate__fadeIn">
          <Typography align={xtraSmall ? undefined : "center"}>
            <FormattedMessage
              defaultMessage="Ускорете растежа на своя бизнес с модерно уеб присъствие и функционалности за оптимално потребителско преживяване"
              id="mainPage.header.description"
            />
          </Typography>
        </ScrollAnimation>
      </Grid>

      <Box
        width={match ? 500 : xtraSmall ? 300 : 400}
        height={match ? 400 : 300}
        mt={match ? 10 : 4}
      >
        <img
          width={match ? 500 : xtraSmall ? 300 : 400}
          height={match ? 400 : 300}
          src="animate-main-image.svg"
          alt="Main"
        />
      </Box>

      <Link underline="none" href="mailto:sales@enter21.io">
        <Button color="secondary" variant="contained">
          Направи запитване
        </Button>
      </Link>
    </Container>
  );
};

export default BannerHeader;
