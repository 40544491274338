import { Button, Grid, useTheme, Box, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ScrollAnimation from "react-animate-on-scroll";

const companyProjects = [
  {
    label: "Veto",
    image: "/veto.jpg",
    href: "https://veto.bg",
  },
  {
    label: "Rem Management",
    image: "/rem.jpg",
    href: "https://remmanagement.bg",
  },
];

const OurWork = () => {
  const theme = useTheme();
  return (
    <Box maxWidth={theme.breakpoints.values.md} ml="auto" mr="auto">
      <Box mb={4}>
        <Typography gutterBottom color="primary" align="center" variant="h4">
          Проекти
        </Typography>
        <Typography align="center">
          Продуктите, които радват нашите клиенти
        </Typography>
      </Box>
      <Grid spacing={6} container justifyContent="center">
        {companyProjects.map((proj) => {
          return (
            <Grid sm={6} item>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce>
                <Box>
                  <Grid
                    spacing={4}
                    alignItems="center"
                    container
                    flexDirection="column"
                  >
                    <Grid item>
                      <img
                        width="100%"
                        height="100%"
                        src={proj.image}
                        alt=""
                        style={{ borderRadius: "4px" }}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        href={proj.href}
                        color="primary"
                        target="_blank"
                        variant="contained"
                      >
                        <Box mr={1}>{proj.label}</Box> <ArrowForwardIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </ScrollAnimation>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default OurWork;
